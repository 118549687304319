import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LazyLoadImage } from "react-lazy-load-image-component"

// Reactstrap
import { Container, Row, Col } from "reactstrap"

const ThankYou = ({ location }) => {
  return (
    <Layout>
      <Seo title="Thank You" />
      <div className="hero-adjustment">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero">
              <h1>ThANK YOU FOR SENDING US A MESSAGE!</h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <h1>We will be in touch with you soon!</h1>
            <h3>
              <span className="btt-btn">
                <Link to="/">Back to Home</Link>
              </span>
            </h3>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ThankYou
